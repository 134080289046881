import React from 'react'
import { Flex, Box, Text, Button, Link } from 'rebass'
import { ALERT_ADD } from 'constants/actionType'
import Message from 'components/Message'
import Switch from 'components/Switch'
import TextInput from 'components/TextInput'
import Icon from 'components/Icon'
import CreatableSelect from 'components/CreatableSelect'
import Editor from 'components/Editor'
import Table from 'components/Table'
import Image from 'components/Image'
import ImageDropzone from 'components/ImageDropzone'
import {
  initializeState,
  handleTextChange,
  handleSelectChange,
  validateForm,
} from 'utilities/formUtil'
import { request } from 'utilities/requestUtil'
import { uploadImage } from 'utilities/imageUtil'
import { serialize } from 'utilities/slateUtil'
import { MdDelete, MdEdit, MdModeEdit } from 'react-icons/md'

export const initialState = (value = {}) => {
  const { shippingProviders, backorder } = value.extra || {}
  return {
    id: value.id || '',
    variantIdx: -1,
    open: false,
    shippingOpen: false,
    shippingProvider: '',
    shippingProviders: getShippingProviders(shippingProviders),
    images: value.images || [],
    imageOpen: false,
    categories: value.categories || [],
    options: value.options || [],
    variants: value.variants || [],
    ...initializeState({
      spu: value.spu || '',
      spec: value.spec
        ? JSON.parse(value.spec)
        : [{ children: [{ text: '' }] }],
      desc: value.desc
        ? JSON.parse(value.desc)
        : [{ children: [{ text: '' }] }],
      categoryId: getCategoryPath(value.categories, value.categoryId),
      tags: value.tags || [],
      tag: value.tag || [],
      shippingWaiverFee: value.shippingWaiverFee || 0,
      price: value.price || 0,
      postedPrice: value.postedPrice || 0,
      sku: value.sku || '',
      barcode: value.barcode || '',
      variant: value.variant,
      backorder: backorder || false,
    }),
  }
}

function getShippingProviders(value) {
  if (value) return value
  return {
    FAMI: { name: 'FAMI', shippingFee: 60, status: 'INACTIVE' },
    UNIMART: { name: 'UNIMART', shippingFee: 60, status: 'INACTIVE' },
    HILIFE: { name: 'HILIFE', shippingFee: 50, status: 'INACTIVE' },
    DOOR_TO_DOOR: {
      name: 'DOOR_TO_DOOR',
      shippingFee: 85,
      status: 'INACTIVE',
    },
  }
}

function getCategoryPath(categories, categoryId) {
  if (!categories || !categoryId) return ''

  return categories.find((item) => item.value === categoryId)
}

const validation = {
  spu: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] },
  ],
  categoryId: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ app, session, state, setState, refs }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  const onVariantDelete = (rowIdx) => {
    let variants = [...state.variants]
    variants.splice(rowIdx, 1)
    setState({ ...state, variants })
  }

  return {
    categoryId: (
      <CreatableSelect
        id="categoryId"
        placeholder="product.field.category"
        options={state.categories}
        value={state.categoryId}
        onChange={async (value) => {
          handleSelectChange('categoryId', state, setState, validation)(value)

          if (value && value.__isNew__) {
            value.value = await addCategory(value, app, session)
            setState({
              ...state,
              categories: [...state.categories, value],
              categoryId: value,
            })
          }
        }}
        errMsg={state.__error__.categoryId}
      />
    ),
    tag: (
      <CreatableSelect
        id="tag"
        isMulti
        placeholder="product.field.tag"
        options={state.tags}
        value={state.tag}
        onChange={async (value) => {
          handleSelectChange('tag', state, setState, validation)(value)
          if (!value) return

          value.forEach(async (item) => {
            if (item && item.__isNew__) {
              await addTag(state.id, item.label, app, session)
              const tag = { value: item.label, label: item.label }
              setState({
                ...state,
                tags: [...state.tags, tag],
                tag: [...state.tag, tag],
              })
            }
          })
        }}
        errMsg={state.__error__.tag}
      />
    ),
    images: (
      <ImageDropzone
        value={state.images || []}
        onUpload={(images) => {
          const productId = state.id
          return addImages({ state, setState, app, session, productId, images })
        }}
        onDelete={(key) => {
          const productId = state.id
          deleteImage({ state, setState, app, session, productId, key })
        }}
        onError={(errorMessages) =>
          errorMessages.forEach((item) => {
            session.dispatch({
              type: ALERT_ADD,
              item: { type: 'error', message: item },
            })
          })
        }
        onDrag={(images) => {
          setState({ ...state, images })
        }}
      />
    ),
    spu: (
      <TextInput
        id="spu"
        label="product.field.spu"
        placeholder="product.field.spu"
        value={state.spu}
        onChange={onTextChange('spu')}
        errMsg={state.__error__.spu}
      />
    ),
    spec: (
      <Editor
        ref={refs.specRef}
        id="spec"
        label="product.field.spec"
        placeholder="product.field.spec"
        value={state.spec}
      />
    ),
    desc: (
      <Editor
        ref={refs.descRef}
        id="desc"
        label="product.field.desc"
        placeholder="product.field.desc"
        value={state.desc}
      />
    ),
    variants: (
      <Table
        columns={[
          {
            id: 'option',
            label: 'product.field.variant',
            render: ({ row, index }) => {
              const { options, sku, barcode, image = {} } = row
              return (
                <Flex alignItems="center">
                  <Image
                    src={image.src ? image.src : null}
                    alt={image.alt}
                    width="83px"
                    height="100px"
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      setState({
                        ...state,
                        imageOpen: true,
                        variant: row,
                        variantIdx: index,
                      })
                    }
                  />
                  <Box ml={2}>
                    <Text fontWeight="bold">
                      {options && options.map((item) => item.value).join(' / ')}
                    </Text>
                    <Text my={1} fontSize={1}>
                      {sku}
                    </Text>
                    <Text my={1} fontSize={1}>
                      {barcode}
                    </Text>
                  </Box>
                </Flex>
              )
            },
          },
          {
            id: 'price',
            label: 'product.field.price',
          },
          {
            id: 'action',
            width: '30px',
            render: ({ row, index }) => (
              <Box sx={{ whiteSpace: 'nowrap' }}>
                <Button
                  type="button"
                  variant="editor"
                  onClick={() =>
                    setState({
                      ...state,
                      open: true,
                      variant: row,
                      variantIdx: index,
                    })
                  }
                >
                  <Icon>
                    <MdModeEdit />
                  </Icon>
                </Button>
                <Button
                  type="button"
                  variant="editor"
                  onClick={() => onVariantDelete(index)}
                >
                  <Icon>
                    <MdDelete />
                  </Icon>
                </Button>
              </Box>
            ),
          },
        ]}
        rows={state.variants}
      />
    ),
    shippingProvider: (action) => (
      <Flex flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center" my={1}>
          <Message id="product.shipping.FAMI" />
          <Flex alignItems="center">
            <Flex mr={3}>
              <Text mr={2}>
                NT${state.shippingProviders.FAMI.shippingFee || 0}
              </Text>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  return action.handleShippingOpen('FAMI')
                }}
              >
                <MdEdit />
              </Link>
            </Flex>
            <Switch
              checked={state.shippingProviders.FAMI.status === 'ACTIVE'}
              onClick={() => {
                handleShippingProviderClick({ state, setState, key: 'FAMI' })
              }}
            />
          </Flex>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" my={1}>
          <Message id="product.shipping.UNIMART" />
          <Flex alignItems="center">
            <Flex mr={3}>
              <Text mr={2}>
                NT${state.shippingProviders.UNIMART.shippingFee || 0}
              </Text>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  return action.handleShippingOpen('UNIMART')
                }}
              >
                <MdEdit />
              </Link>
            </Flex>
            <Switch
              checked={state.shippingProviders.UNIMART.status === 'ACTIVE'}
              onClick={() => {
                handleShippingProviderClick({ state, setState, key: 'UNIMART' })
              }}
            />
          </Flex>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" my={1}>
          <Message id="product.shipping.HILIFE" />
          <Flex alignItems="center">
            <Flex mr={3}>
              <Text mr={2}>
                NT${state.shippingProviders.HILIFE.shippingFee || 0}
              </Text>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  return action.handleShippingOpen('HILIFE')
                }}
              >
                <MdEdit />
              </Link>
            </Flex>
            <Switch
              checked={state.shippingProviders.HILIFE.status === 'ACTIVE'}
              onClick={() => {
                handleShippingProviderClick({ state, setState, key: 'HILIFE' })
              }}
            />
          </Flex>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" my={1}>
          <Message id="product.shipping.DOOR_TO_DOOR" />
          <Flex alignItems="center">
            <Flex mr={3}>
              <Text mr={2}>
                NT${state.shippingProviders.DOOR_TO_DOOR.shippingFee || 0}
              </Text>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  return action.handleShippingOpen('DOOR_TO_DOOR')
                }}
              >
                <MdEdit />
              </Link>
            </Flex>
            <Switch
              checked={state.shippingProviders.DOOR_TO_DOOR.status === 'ACTIVE'}
              onClick={() => {
                handleShippingProviderClick({
                  state,
                  setState,
                  key: 'DOOR_TO_DOOR',
                })
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    ),
    shippingWaiverFee: (
      <TextInput
        id="shippingWaiverFee"
        type="number"
        min={0}
        label="product.field.shippingWaiverFee"
        value={state.shippingWaiverFee}
        onChange={onTextChange('shippingWaiverFee')}
        errMsg={state.__error__.shippingWaiverFee}
      />
    ),
    backorder: (
      <Flex alignItems="center">
        <Message flex={1} id="estore.field.backorder" />
        <Switch
          checked={state.backorder}
          onClick={() => {
            setState({ ...state, backorder: !state.backorder })
          }}
        />
      </Flex>
    ),
  }
}

function handleShippingProviderClick({ state, setState, key }) {
  const checked = state.shippingProviders[key].status === 'ACTIVE'
  const shippingProviders = { ...state.shippingProviders }
  shippingProviders[key].status = checked ? 'INACIVE' : 'ACTIVE'
  setState({ ...state, shippingProviders })
}

export const handlers = ({
  state,
  setState,
  session,
  app,
  history,
  match,
  refs,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session })
    let id = match.params.id

    if (id) {
      const product = await getProduct({ app, session, id })
      setState(initialState({ ...data, ...product }))
      return
    }

    id = await createProductDraft({ app, session })
    setState({ ...state, ...data, id, status: 'DRAFT' })
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    if (state.variants.length === 0) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'error', message: 'error.product.variantRequired' },
      })
      return
    }

    const ok = await editProduct(state, app, session, refs)
    if (ok) {
      session.dispatch({
        type: ALERT_ADD,
        item: { type: 'success', message: 'success.save' },
      })
    }
  },
  handleDelete: (id) => async () => {
    const variables = { id }
    const query = `
      mutation ($id: ID!) {
        deleteProduct(id: $id)
      }
    `
    const [ok] = await request({ query, variables }, { session, app })
    if (!ok) {
      return
    }
  },
  handleShippingOpen: (name) => {
    let shippingProvider = state.shippingProviders[name]
    if (!shippingProvider) {
      shippingProvider = { name, status: 'INACTIVE', shippingFee: 0 }
    }
    setState({ ...state, shippingOpen: true, shippingProvider })
  },
  handleShippingClose: () => {
    setState({ ...state, shippingOpen: false, shippingProvider: '' })
  },
  handleShippingSubmit: (value) => {
    const { shippingProvider: name, shippingFee } = value
    const { status } = state.shippingProviders[name]
    const shippingProviders = { ...state.shippingProviders }
    shippingProviders[name] = {
      name,
      status,
      shippingFee: parseFloat(shippingFee),
    }

    setState({
      ...state,
      shippingOpen: false,
      shippingProvider: '',
      shippingProviders,
    })
  },
  handleVariantOpen: () => {
    setState({ ...state, open: true })
  },
  handleVariantClose: () => {
    setState({ ...state, open: false, variant: {}, variantIdx: -1 })
  },
  handleVariantSubmit: (variant) => {
    const variants = [...state.variants]
    if (state.variantIdx === -1) {
      variants.push(variant)
    } else {
      variants.splice(state.variantIdx, 1, variant)
    }

    const options = variants.reduce((list, item) => {
      item.options.forEach(({ name, value }) => {
        if (name && value) {
          const option = list.find((opt) => opt.name === name)
          if (option) {
            if (!option.value.some((item) => item === value)) {
              option.value = [...option.value, value]
            }
          } else {
            list.push({ name, value: [value] })
          }
        }
      })
      return list
    }, [])

    setState({
      ...state,
      open: false,
      options,
      variants,
      variant: {},
      variantIdx: -1,
    })
  },
  handleImageClose: () => {
    setState({ ...state, imageOpen: false })
  },
  handleImageConfirm: (variant) => {
    if (state.variantIdx === -1) return

    const variants = [...state.variants]
    variants.splice(state.variantIdx, 1, variant)

    setState({
      ...state,
      imageOpen: false,
      variants,
      variant: {},
      variantIdx: -1,
    })
  },
})

async function getData({ app, session }) {
  const { merchantId } = app.state.staff
  const variables = { merchantId }
  const query = `
    query($merchantId: ID!, $input: CategoryQueryInput) {
      categories(merchantId: $merchantId, input: $input) {
        id
        name
        path
      }
      productTags(merchantId: $merchantId) {
        value
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session })
  if (!ok) return null

  return {
    categories: data.categories.map((item) => ({
      value: item.id,
      label: getCategoryLabel(item.path, item.name),
    })),
    tags: data.productTags.map(({ value }) => ({ value, label: value })),
  }
}

function getCategoryLabel(path, name) {
  if (!path) return name

  return (
    path.reduce((result, item) => {
      result += item.name
      return result
    }, '') +
    ' > ' +
    name
  )
}

async function addCategory(value, app, session) {
  const variables = { input: { name: value.label } }
  const query = `
    mutation ($input: CategoryInput!) {
      addCategory(input: $input)
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null
  return data.addCategory
}

async function addTag(productId, value, app, session) {
  const variables = { input: { productId, value } }
  const query = `
    mutation ($input: ProductTagInput!) {
      addProductTag(input: $input)
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  return data.addProductTag
}

async function createProductDraft({ app, session }) {
  const query = `
    mutation {
      createProductDraft
    }
  `
  const [ok, data] = await request({ query }, { session, app })
  if (!ok) return null
  return data.createProductDraft
}

async function getProduct({ app, session, id }) {
  const { merchantId } = app.state.staff
  const variables = { id, merchantId }
  const query = `
    query($id: ID!, $merchantId: ID!) {
      product(id: $id) {
        id
        merchantId
        categoryId
        spu
        spec
        desc
        images {
          src
          alt
          variantId
        }
        options {
          name
          value
        }
        shippingWaiverFee
        extra
        status
      }
      productVariants(productId: $id) {
        id
        sku
        barcode
        price
        postedPrice
        options {
          name
          value
        }
        image {
          src
          alt
        }
      }
      productTags(merchantId: $merchantId, productId: $id) {
        value
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return null

  const { product, productVariants } = data
  return {
    ...product,
    variants: productVariants,
    tag: data.productTags.map(({ value }) => ({ value, label: value })),
  }
}

async function editProduct(value, app, session, refs) {
  const { specRef, descRef } = refs
  const spec = specRef.current && specRef.current.getValue()
  const desc = specRef.current && descRef.current.getValue()
  const input = {
    spu: value.spu,
    spec: JSON.stringify(spec),
    specHtml: serialize(spec),
    desc: JSON.stringify(desc),
    descHtml: serialize(desc),
    categoryId: value.categoryId.value,
    options: value.options,
    images: value.images.map((item) => formatImage(item)),
    variants: value.variants.map((item) => ({
      id: item.id,
      options: item.options,
      sku: item.sku,
      barcode: item.barcode,
      price: parseFloat(item.price),
      postedPrice: parseFloat(item.postedPrice),
      image: formatImage(item.image),
    })),
    tags: value.tag.map((item) => item.value),
    shippingWaiverFee: parseFloat(value.shippingWaiverFee),
    shippingProviders: value.shippingProviders,
    backorder: value.backorder,
  }

  const variables = { id: value.id, input }
  const query = `
    mutation ($id: ID!, $input: ProductInput!) {
      editProduct(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  return ok
}

function formatImage(image) {
  if (!image || !image.src) return null
  const { src, alt, variantId } = image
  return { src, alt, variantId }
}

async function addImages({ app, state, setState, session, productId, images }) {
  const newImages = await Promise.all(
    images.map(async (image) => {
      const src = await addImage({ app, session, productId, image })
      image.src = src
      return image
    })
  )
  setTimeout(
    () => setState({ ...state, images: [...state.images, ...newImages] }),
    300
  )
  return newImages
}

async function addImage({ app, session, productId, image }) {
  const variables = { productId, contentType: image.type }
  const query = `
    mutation ($productId: ID!, $contentType: String!) {
      addImage(productId: $productId, contentType: $contentType) 
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return false

  const { url, fields } = data.addImage
  uploadImage({ url, fields, image })
  return fields.key
}

async function deleteImage({ state, setState, app, session, productId, key }) {
  const variables = { productId, key }
  const query = `
    mutation ($productId: ID!, $key: String!) {
      deleteImage(productId: $productId, key: $key) 
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (ok) {
    const images = [...state.images]
    const idx = images.findIndex((item) => item.src === key)
    images.splice(idx, 1)
    setState({ ...state, images })
  }
}
